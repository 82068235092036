<template>
  <div class="resetPassword xi" style="position: relative">
    <nh-com></nh-com>
    <div class="page">
      <div class="dictionBoximg">
        <img v-if="isZhong == 1" src="../../assets/img/zcdbg.png" mode="" />
        <img v-else src="../../assets/img/cdbg02.png" mode="" />
      </div>
      <div class="dictionBox">
        <div class="boxinput flex-sb" :class="isZhong == 1 ? 'zhongShadow zhongBorder' : 'xiShadow xiBorder'">
          <!-- <div class="flex-start" style="width: calc(100% - 180px)">
            <text class="cuIcon-search" style="margin-right: 10rpx; font-size: 19px" :class="isZhong == 1 ? 'zhongC' : 'xiC'"></text>
            <el-input placeholder="请输入内容" v-model="searchValue" class="input-with-select">
              <el-button @click="searchRes" slot="append" icon="el-icon-search">检索</el-button>
            </el-input>
          </div> -->
          <div class="flex-start" style="width: calc(100% - 180px); height: 30px">
            <i class="el-icon-search" :class="isZhong == 1 ? 'zhongC' : 'xiC'" style="margin: 0 10px; font-size: 15px"></i>
            <input @confirm="searchRes" focus :adjust-position="false" v-model="searchValue" type="text" placeholder="输入需要查询的字或词..." confirm-type="search" />
          </div>

          <!-- <div class="searchBtn flex-end" style="width: 140px">
            <div class="cuIcon-roundclose" @click="clearIcon" style="margin-right: 5px; font-size: 19px" :class="isZhong == 1 ? 'zhongC' : 'xiC'" v-if="searchValue"></div>
          </div> -->
          <div class="searchBox flex-end" style="width: 140px">
            <i class="el-icon-search" :class="isZhong == 1 ? 'zhongC' : 'xiC'" style="margin-right: 10px; font-size: 15px" @click="clearIcon" v-if="searchValue"></i>
            <div class="searchBtn" :class="isZhong == 1 ? 'zhongLineBox' : 'xiLineBox'" @click="searchRes">检索</div>
          </div>
        </div>
        <div class="flex-center top-tab" :class="isZhong == 1 ? 'zhong' : 'xi'">
          <el-radio-group v-model="activeTab">
            <el-radio v-for="item in tabMenus" :key="item.id" :label="item.id" @change="radioChange(item)">{{ item.name }} </el-radio>
          </el-radio-group>
        </div>

        <div class="zsearchTextCenterBox" v-if="leftList.length > 0">
          <div class="flex-start align-s">
            <div class="" style="width: 46px; line-height: 30px">分类：</div>
            <div class="leftbox" style="width: calc(100% - 46px)">
              <div class="leftboxtitle" :class="[curIndex == -1 && isZhong == 1 ? 'zhongCheck zhongC' : '', curIndex == -1 && isZhong == 2 ? 'xiCheck xiC' : '']" @click="changeCategory(0, -1)">全部</div>
              <div class="leftboxtitle" :class="[curIndex == index && isZhong == 1 ? 'zhongCheck zhongC' : '', curIndex == index && isZhong == 2 ? 'xiCheck xiC' : '']" v-for="(item, index) in leftList" :key="index" @click="changeCategory(item.id, index)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; background-color: #fff">
          <div class="rightbox">
            <div class="rightboxTitle">
              <div class="item" v-for="(itemSpell, indexSpell) in rightList" :key="indexSpell" :id="'id' + itemSpell.firstSpell" style="padding: 10rpx 0" @click="godetail(itemSpell)">
                {{ itemSpell.name }}
              </div>
            </div>
            <div v-if="rightList.length == 0">
              <img class="no_data" src="../../assets/img/nodata1.png" v-if="rightList.length == 0" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <rt-bar></rt-bar>
    <UpgradeMsgBox ref="upgradeMsgBox" @currentlyAvailable="getCurrentlyAvailable" :fullPath="$router.currentRoute.fullPath"></UpgradeMsgBox>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
// import rtBar from "../../components/right";
import nhCom from "../../components/navheader";
import { getDictionaryLIst } from "../../components/dictionaryLIst/dictionaryLIstindex";
export default {
  name: "resetPassword",
  components: {
    ftCom,
    nhCom,
    // rtBar,
  },
  data() {
    var getDictionaryLIstion = new getDictionaryLIst(this.TokenClient, this.Services.Statics);
    return {
      getDictionaryLIstmain: getDictionaryLIstion,
      isZhong: 1,
      rColor: "#E7A365",
      activeTab: 1,
      leftList: [],
      curIndex: -1,
      categoryId: 0,
      rightList: [],
      dictId: "",
      tabMenus: [],
      keyWord: "",
      pageIndex: 1,
      pageTotal: 1,
      pageSize: 24,
      message: "",
      searchValue: "",
      currentlyAvailable: false,
    };
  },
  mounted() {
    this.$bus.$emit("isShowChangeSize");
    this.activeTab = Number(this.$route.query.dictId);
    this.dictId = Number(this.$route.query.dictId);
    this.getDictList();
  },
  methods: {
    getDictList() {
      var _this = this;
      _this.getDictionaryLIstmain.getDict(
        function (data) {
          _this.tabMenus = data.data;
          _this.zhongOrXiData();
        },
        function (error) {
          _this.tabMenus = [];
          console.log(error);
        }
      );
    },
    getCurrentlyAvailable(data) {
      this.currentlyAvailable = data;
    },
    godetail(e) {
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return;
      }
      this.$router.push({
        path: "/dictionaryLIst/dictionaryDetail",
        query: {
          id: e.id,
        },
      });
    },
    zhongOrXiData() {
      this.tabMenus.forEach((item) => {
        if (item.id == this.dictId) {
          if (item.name.indexOf("中医") > -1) {
            this.isZhong = 1;
            this.rColor = "#E7A365";
          } else {
            this.isZhong = 2;
            this.rColor = "#04AFA9";
          }
        }
      });
    },
    radioChange(e) {
      this.activeTab = e.id;
      this.dictId = this.activeTab;
      this.zhongOrXiData();
      this.categoryId = 0;
      this.curIndex = -1;
      if (this.searchValue) {
        this.getDictDataByAll();
      } else {
        this.clearIcon();
      }
    },
    getDictDataByAll() {
      var _this = this;
      let categoryIdindex = _this.categoryId ? _this.categoryId : "0";
      let keyWord = _this.searchValue ? _this.searchValue : "%20";
      let path = `${_this.dictId}/${categoryIdindex}/${keyWord}/%20/%20/0`;
      _this.getDictionaryLIstmain.getDictKeywordSearch(
        path,
        function (data) {
          if (data.code == 200) {
            let resdata = data.data;
            _this.leftList = resdata.categories;
            _this.dataList = resdata.keywords;
            _this.pageTotal = resdata.keywordTotal;
            _this.pageIndex = 1;
            _this.rightList = _this.dataList;
          }
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.rightList = [];
            _this.leftList = [];
          }
        }
      );
    },
    getDictData() {
      var _this = this;
      let categoryIdindex = this.categoryId ? this.categoryId : "0";
      let keyWord = this.searchValue ? this.searchValue : "%20";
      let path = `${this.dictId}/${categoryIdindex}/${keyWord}/%20/%20/0`;
      _this.getDictionaryLIstmain.getDictKeywordSearch(
        path,
        function (data) {
          if (data.code == 200) {
            let resdata = data.data;
            _this.dataList = resdata.keywords;
            _this.pageTotal = resdata.keywordTotal;
            _this.pageIndex = 1;
            _this.rightList = _this.dataList;
          }
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.rightList = [];
            _this.leftList = [];
          }
        }
      );
    },
    searchRes() {
      if (this.searchValue) {
        if (this.categoryId) {
          this.getDictData();
        } else {
          this.getDictDataByAll();
        }
      } else {
        this.$message.error("输入需要查询的字或词");
        this.clearIcon();
      }
    },
    clearIcon() {
      this.searchValue = "";
      this.leftList = [];
      this.dataList = [];
      this.pageTotal = [];
      this.rightList = [];
    },
    changeCategory(id, e) {
      this.curIndex = e;
      this.categoryId = id;
      if (e == -1) {
        this.getDictDataByAll();
      } else {
        this.getDictData();
      }
    },
    getMenu() {
      var _this = this;
      _this.getDictionaryLIstmain.getDictMenu(
        function (data) {
          if (data.code == 200) {
            let resdata = data.data;
            let data1 = [];
            let data2 = [];
            let data3 = [];
            resdata.forEach((item) => {
              if (item.dictName.indexOf("中医") > -1 && item.menuType == "Order") {
                data1 = item.menuItems;
              } else if (item.dictName.indexOf("中医") > -1 && item.menuType == "Spell") {
                data2 = item.menuItems;
              } else if (item.dictName.indexOf("西医") > -1 && item.menuType == "Spell") {
                data3 = item.menuItems;
              }
            });
            for (var key in data1) {
              let map = {
                name: key,
                subMenu: data1[key],
              };
              _this.zCategoryList1.push(map);
            }
            for (var key in data2) {
              let map = {
                name: key,
                subMenu: data2[key],
              };
              _this.zCategoryList2.push(map);
            }
            _this.zCategoryList2.sort((x, y) => {
              return x.name.localeCompare(y.name);
            });
            for (var key in data3) {
              let map = {
                name: key,
                subMenu: data3[key],
              };
              _this.xCategoryList.push(map);
            }
            _this.xCategoryList.sort((x, y) => {
              return x.name.localeCompare(y.name);
            });
            console.log(_this.zCategoryList1);
          }
        },
        function (error) {
          if (err.statusCode == 404) {
            _this.tabMenus = [];
            _this.zCategoryList1 = [];
            _this.zCategoryList2 = [];
            _this.xCategoryList = [];
          }
        }
      );
    },
    changeCategory(id, e) {
      this.curIndex = e;
      this.categoryId = id;
      if (e == -1) {
        this.getDictDataByAll();
      } else {
        this.getDictData();
      }
    },
    // 数字转中文
    toChinesNum(num) {
      let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
      let unit = ["", "十", "百", "千", "万"];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp.toString().split("").reverse();
        let newNum = "";
        let newArr = [];
        strArr.forEach((item, index) => {
          newArr.unshift(item === "0" ? changeNum[item] : changeNum[item] + unit[index]);
        });
        let numArr = [];
        newArr.forEach((m, n) => {
          if (m !== "零") numArr.push(n);
        });
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === "零") {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m;
              }
            } else {
              newNum += m;
            }
          });
        } else {
          newNum = newArr[0];
        }
        return newNum;
      };
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) {
        noWan = "0" + noWan;
      }
      return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";

.page {
  position: relative;
  background: #fff;
  min-height: 99vh;
  overflow-y: hidden;
}
.zsearchTextBox {
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.lineBox {
  width: 33px;
  height: 4px;
  border-radius: 2px;
}

.zhongLineBox {
  background: linear-gradient(90deg, #e7a365, #e98f3d);
}

.xiLineBox {
  background: linear-gradient(90deg, #23c8c2, #04afa9);
}

.boxinput {
  position: relative;
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  background: #fff;
  /* padding-left: 40rpx; */
  border-radius: 11px;
}
.boxinput input {
  font-size: 16px;
  width: 100%;
  height: 30px;
}
.searchBtn {
  font-size: 19px;
  color: #ffffff;
  padding: 10px 24px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.zhongShadow {
  box-shadow: 0px 3px 5px 0px rgba(231, 163, 101, 0.2);
}

.xiShadow {
  box-shadow: 0px 3px 5px 0px rgba(57, 210, 205, 0.2);
}

.searchBtn {
  position: absolute;
  top: 2.5px;
  right: 3px;
  font-size: 19px;
  color: #ffffff;
  padding: 10px 24px;
  border-radius: 8px;
}
/* .boxinput {
  position: relative;
  width: 100%;
  padding: 3px;
  background: #fff;
  padding-left: 20px;
  border-radius: 11px;
  margin: 20px auto;
} */
.boxinput {
  position: relative;
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  background: #fff;
  /* padding-left: 20px; */
  border-radius: 11px;
  margin: 20px auto;
  /* justify-content: flex-end; */
}
/* .boxinput input {
  font-size: 16px;
  width: 100%;
} */

/* 	.zsearchTextTable {
		display: flex;
		align-items: center;
	}
	.zsearchTextTable div {
		margin:auto;
	}
	.zsearchTextTable div text{
		text-align: center;
		
	} */
.zsearchTextTable1 {
  font-size: 16px;
  color: #666666;
  padding: 7px 20px;
}

.zsearchTextTable2 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  padding: 5px 20px;
}

.searchTextTable {
  margin: auto;
}

.searchTextTable1 {
  width: 50%;
  text-align: center;
  font-size: 30rpx;
  color: #ffffff;
  padding: 15rpx 20rpx;
}

.zsearchTextCenterBox {
  width: 98%;
  border-radius: 11px;
}

.zsearchTextCenter {
  align-items: center;
  position: relative;
}

.zline {
  width: 25px;
  height: 2px;
  background: #cb9b58;
  border-radius: 1px;
  position: absolute;
  bottom: -1px;
}

.line {
  width: 30rpx;
  height: 3rpx;
  background: #00a692;
  border-radius: 2rpx;
  position: absolute;
  bottom: 0;
}

.choosebox {
  width: 40rpx;
  height: 40rpx;
  line-height: 40rpx;
  background: #00c4ac;
  border-radius: 50%;
  text-align: center;
  font-size: 24rpx;
  font-weight: 500;
  color: #fff;
}

.zchoosebox {
  width: 40rpx;
  height: 40rpx;
  line-height: 40rpx;
  background: #e49a57;
  border-radius: 50%;
  text-align: center;
  font-size: 24rpx;
  font-weight: 500;
  color: #fff;
}

.zimuchoosebox {
  width: 40rpx;
  height: 40rpx;
  line-height: 40rpx;
  background: #cb9b58;
  border-radius: 50%;
  text-align: center;
  font-size: 24rpx;
  font-weight: 500;
  color: #fff;
}

.addressSidebar {
  position: fixed;
  top: 25%;
  right: 5%;
  /* background-color: #cccccc;
		border-radius: 30rpx; */
  /* transform: translateY(-50%); */
  /* z-index: 99; */
}

.addressIndex {
  padding: 10rpx 20rpx;
  font-size: 24rpx;
  font-weight: 500;
  line-height: 1;
  text-align: center;
}

.addressAlert {
  position: fixed;
  top: 50%;
  right: 90rpx;
  /* 		z-index: 99; */
  margin-top: -60rpx;
  width: 120rpx;
  height: 120rpx;
  font-size: 50rpx;
  color: #fff;
  border-radius: 24rpx;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.leftboxtitle {
  width: 9%;
  font-size: 13px;
  color: #666666;
  background: #ebe9e8;
  border-radius: 5px;
  padding: 6px 1%;
  margin-right: 1.1%;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
}

.leftboxtitle:nth-child(8n) {
  margin-right: 0;
}

.zhongCheck {
  background: #f9e9da;
}

.xiCheck {
  background: #ddf3f3;
}

.rightbox {
  margin: 0 auto;
  background-color: #fff;
  width: 98%;
  overflow: auto;
  /* height: 80vh; */
}

.rightboxTitle1 {
  width: 100%;
  padding-top: 10px;
  font-size: 16px;
  margin-bottom: 30px;
  color: #333333;
}
.rightboxTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
  overflow: auto;
  margin-bottom: 20px;
}

.rightboxTitle .item {
  width: 31%;
  padding: 10px 0 !important;
  font-size: 13px;
  color: #333333;
  margin-bottom: 10px;
  border-bottom: 1px dashed #b7b6b6;
  margin-right: 3.5%;
  cursor: pointer;
}

.rightboxTitle .item:nth-child(3n) {
  margin-right: 0;
}
.popup-content {
  padding: 30rpx;
  background-color: #ffffff;
  height: 100vh;
  border-radius: 0;
  max-width: 400px;
}
.dictionBox {
  width: 80%;
  margin-left: 10%;
}

.dictionBoximg {
  width: 100%;
  height: 160px;
  position: absolute;
  top: 0;
}

.dictionBoximg img {
  width: 100%;
  height: 100%;
}

.c-tab {
  margin: 10px auto 0;
}
.tab-box {
  width: 98%;
  margin: 0 auto;
}

.tab-item {
  height: 43px;
}
.t {
  font-size: 13px;
  font-weight: 400;
  line-height: 29px;
  color: #666666;
}

.activeTab {
  color: #cc772a;
}

.b-line {
  width: 96px;
  height: 3px;
}

.b-lineActive {
  background: #cc772a;
}
.c-div {
  width: 10px;
  height: 1px;
}

.c-div2 {
  width: 30px;
  height: 1px;
}

.select-div {
  width: 98%;
  margin: 0 auto;
  background-color: #fff;
}
.select-div .item {
  width: 9%;
  padding: 10px 0;
  margin-right: 1.1%;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 400;
  background: #f0f0f0;
  color: #333333;
}
.select-div .item:nth-child(10n) {
  margin-right: 0;
}
.activeItem {
  background: #f9e9da;
  color: #cc772a;
}
.resetting-btn {
  padding: 5px 15px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}
.flexlr {
  display: flex;
  justify-content: space-between;
}

.flexlc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-sa {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-start-c {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-end-c {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex-sb-c {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-sa-c {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.resetting-btn {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  /* background: linear-gradient(-90deg, #e8ad76, #e49751); */
  border-radius: 24px;
}
.zhongC {
  color: #e89f3a !important;
}
.zhongBg {
  background-color: #e89f3a !important;
}
/* .zhongBorder {
		border: 1px solid #E89F3A;
	} */
.xiC {
  color: #04afa9 !important;
}
/* .xiBorder {
		border: 1px solid #04AFA9;
	} */
.xiBg {
  background-color: #04afa9 !important;
}
.mb {
  margin-bottom: 75px !important;
}
.top-tab {
  margin: 15px auto;
}
.t1 {
  color: #666666;
}

.no_data {
  display: block;
  width: 185px;
  height: 100px;
  margin: 0 auto;
  padding: 30px 0;
  box-sizing: content-box;
}
.zhongBorder {
  border: 1px solid #e89f3a;
}
.xiBorder {
  border: 1px solid #04afa9;
}
.xi ::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #04afa9;
  background: #04afa9;
}

.xi ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #04afa9;
}
.zhong ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #e7a365;
}

.zhong ::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #e7a365;
  background: #e7a365;
}
.cursor {
  cursor: pointer;
}
</style>
<style>
.personal-center {
  display: none;
}
</style>
